export const environment = {
  production: true,
  apiUrl: "https://certificadostradicionylibertad.co/api/",
  apiUrlV2: "https://certificadostradicionylibertad.co/api/v2",
  apiBlog: "https://blog-megapagos-default-rtdb.firebaseio.com/",
  PASS_JWT: "QT95NMJWDKELC6R2V8YAH0P3IS4OX1",
  firebaseConfig: {
    apiKey: "AIzaSyBZwaFpyh5VXlCBsW5WFYXvhp__evzBorE",
    authDomain: "blog-megapagos.firebaseapp.com",
    projectId: "blog-megapagos",
    storageBucket: "blog-megapagos.appspot.com",
    messagingSenderId: "14759754646",
    appId: "1:14759754646:web:a0192d3301667dba69bbb7",
    measurementId: "G-RZ87F8MGM7"
  },
  inMaintenance: false
}
